<template>
  <div :id="'pro_'+id" class="product_box">
    <div class="pro_info flex-r-s-c">
      <van-image width="2.56rem" height="2.56rem" :src="myinfo.proIconUrl" @click="jumpDetail(myinfo.productId)" />
      <div style="margin-left:0.2rem">
        <div class="pro_name presell_pro-top van-multi-ellipsis--l2" @click="jumpDetail(myinfo.productId)">
          <van-image v-if="myinfo.releaseFlag === 3" width="0.75rem" height="0.43rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/product/presell_tag.png"
          />
          {{ myinfo.proName }}
        </div>
        <div class="pro_brand_model van-ellipsis" @click="jumpDetail(myinfo.productId)">{{ myinfo.proBrand }} |
          {{ myinfo.proModel }}</div>
        <!-- <div class="pro_tags">1</div> -->
        <div class="pro_bottom flex-r-sb-c">
          <div class="pro_money">￥<span
            style="font-size: 0.49rem;"
          >{{ myinfo.showPrice === 0? myinfo.proPrice :'详询客服' }}</span></div>
          <div class="pro_btn">
            <van-icon v-if="myinfo.showPrice === 0 && myinfo.flashId === null" name="cart" @click.stop="toCart(myinfo)" />
            <van-icon v-if="myinfo.showPrice === 1" name="service" @click.stop="openKeFu" />
          </div>
        </div>
      </div>
    </div>
    <!-- 客服组件 -->
    <kefuBtn ref="mykefuBtn" />
  </div>
</template>

<script>
import kefuBtn from '@/components/kefu_btn'
// import { shoppingCart_addUnion } from '@/api/cart'
export default {
  components: { kefuBtn },
  props: {
    info: { type: Object, default: () => { } },
    id: { type: Number, default: null }
  },
  data() {
    return {
      myinfo: this.info
    }
  },
  methods: {
    // 加入购物车
    toCart(item) {
      // const parms = {
      //   companyId: '1000',
      //   products: [{ productId: item.productId, proNumber: item.minQuantity }]
      // }
      // console.log(parms)
      // shoppingCart_addUnion(parms).then(res => {
      //   this.$toast({ message: '加入成功！', duration: 1 * 1000 })
      //   this.$utils.cartNum(this.$store)
      // })
      this.$emit('addCart', item)
    },
    // 进入详情
    jumpDetail(pid) {
      sessionStorage.setItem('classify_pro_id', 'pro_' + this.id)
      this.$router.push('/product/detail?productId=' + pid)
    },
    // 客服
    openKeFu() { this.$refs.mykefuBtn.changeShow(true) }
  }
}
</script>
